.footer-main{
    color: white;
    background: black;
    width: 100%;
    text-decoration: none;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

}
.icons-footer{
    padding: 1.5rem;
    background: black;
    width: 100%;
    color: white;
    justify-content: center;
    display: flex;

}
i{
    padding-left: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    color: white;

}
.icons-footer i a{
    color: white;
    text-decoration: none;

}

Link:hover{
    color: #eeee;
}