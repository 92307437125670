.section-usercard {
    height: 35vh;
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
}

.div-usercard{
    background: linear-gradient(rgba(0, 50, 65, 0.1), rgba(255, 214, 219, 0.1));
    height: 25rem;
    width: 60rem;
    border-radius: 2rem;
    color: black;
    padding: 3rem;
    box-shadow: 0 2rem 5.5rem 0 rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
}

.article-usercard{
    background: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    align-items: flex-start;
    width: 100%;

}

.img-robin{
    height: 12rem;
    width: 12rem;
    border-radius: 3rem;
    border: 2px solid orange;

}

.links-img{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;

}
.item-icons{
    color: black;
}

.item-icon{
    color: black;
    font-size: 1.6rem;
    padding: 0.5rem 1rem;
}
.item-icon:hover{
    color: rgba(0,0,0,0.5);
}
.text-card-about{
    font-size: 1.3rem;

}
.text-card-tittle{
    font-size: 2rem;

}

@media only screen and (max-width: 1024px){
    .div-usercard {
        flex-direction: column;
        height: 37rem;
        width: 40rem;
    }

}
.section-usercard {
    height: 75vh;
}


@media only screen and (max-width: 800px){
.div-usercard{
    flex-direction: column;
    height: 45rem;
    width: 38rem;

}
    .item-icon{
        margin-top: -2rem;
    }
    .section-usercard {
        height: 65vh;
    }
    .article-usercard{
        padding: 1rem;
        margin-top: -10rem;
        margin-bottom: -3rem;
    }
}
