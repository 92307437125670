i{
    font-size: 2.5rem;

}
.header-main{
    color: white;
    background: black;
    width: 100%;
    text-decoration: none;
    height: 8rem;
    display: flex;
    align-items: center;
    padding: 2rem;
    justify-content: center;


}

.header-links {
    display: flex;
    flex-direction: row;

}

.header-link a {
    text-decoration: none;
    color: white;
}

.header-link {
    list-style: none;
    padding: 1rem;
}

.header-link{
    font-size: 2rem;


}
.header-text{
    font-size: 2rem;
    padding: 1rem;


}

.header-contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background: black;
}
.header-main i{
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 1rem;
    align-items: center;
    padding-top: 2rem;

}

.header-main a{
    text-decoration: none;

}
.header-main:hover{
    color: black;
}