.container-home{
    background:linear-gradient(to bottom right, rgba(105, 120, 190,0.6), rgba(129, 21, 45, 0.2)),url("../../public/img/heading_home.jpg");
    background-position: top;
    font-weight: bold;
    font-style: italic;
    font-size: 2.5rem;
    color: white;
    min-height: 90vh;
    width: 100%;
    text-shadow: black 0.3rem 0.2rem;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}


.text-home{
    padding: 6rem;

}
.portfolio-work{
    margin-left: 10rem;
    flex-wrap: wrap;
    height: 6rem;
    width: 12rem;
    justify-content: center;
    border: none;
    background: #6356e5;
    color: white;
    border-radius: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: -2rem;
    font-weight: 900;
}
.portfolio-work:hover{
    color: black;
    background: #55b3f3;
}
