
.button-section{

    height: 20%;
    background: white;
    padding: 2.5rem;

}
.readmore-button{
    border: none;
    background: #6356e5;
    color: white;
    border-radius: 1rem;
    padding: 1rem 2rem;
    margin-top: -2rem;
}

.readmore-button:hover{
    color: black;
    background: #55b3f3;
}
.main-item-container {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 2rem 20rem;

}
.figure-card{

}
article{
    width: 42rem;
    height: 55rem;
    border-radius: 1.5rem;
    box-shadow: 0 2rem 5.5rem 0 rgba(0,0,0,0.4);
    transition: 0.4s;
    padding-bottom: 5.5rem;
    background: white;



}


@media screen and (max-width: 1300px) {

}
.info-section{
    padding: 1rem;
}

article header{
    height: 10%;
    background: black;
    color: #eeeeee;
    display: flex;
    align-items: center;
    padding: 2rem;
    margin-top: -1rem;





}
article{
    width: 37rem;
}
.figure-card img{
    height: 35rem;
    width: 100%;
    object-fit: cover;
    background-position: center;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
}
article header h2{
    font-size: 200%;
    font-weight: 700;


}
article section{
    padding: 2rem;
    background: white;
    height: 25%;
    display: flex;
    align-items: center;
    font-size: 1.6em;




}
article div{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    align-items: center;
    font-size: 2rem;
    padding-top: 0.8rem;

}
