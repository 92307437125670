.portfolio-body{

    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 100%;
}
body{
    background: linear-gradient(rgba(48, 56, 65, 0.1), rgba(211, 214, 219, 0.1));
    min-height: 100vh;
    width: 100%;


}

.text-field{
    display: flex;
    justify-content: center;
    color: white;
    padding: 2rem;
    background: black;
    font-weight: 700;
    align-items: center;
    font-size: 4rem;


}
.text-field i{
    align-items: center;
    display: flex;
    justify-content: center;
    mso-vertical-page-align: center;
    padding: 1rem;
    font-size: 4rem;
    padding-left: 2rem;
}


.info-field-pf{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background:linear-gradient(to bottom right, rgba(105, 120, 190,0.6), rgba(129, 21, 45, 0.2)),url("../../public/img/heading_pf.jpg");
    object-fit: cover;
    background-position: center;
    font-weight: bold;
    font-style: italic;
    font-size: 2.1rem;
    text-align: center;
    color: white;
    height:25vh;
    text-shadow: black 0.3rem 0.2rem;


}

.Portfolio{
    width: 100%;

}

.li-portfolio-items{

}
.ol-portfolio-contents{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.portfolio-body{
padding-right: -2rem;
    padding-left: -2rem;
}
@media screen and (max-width: 1300px) {
    .info-field-pf{
        padding: 5rem;
        font-size: 1.7rem;
    }

}