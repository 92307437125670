.portfolio-details-section{
    height: 50rem;
    padding: 4rem;
    width: 100%;


}
.test{
    height: 100vh;
    width: 50%;
    padding: 5rem 5rem 5rem 2rem;
}

.text1{
    font-size: 2rem;
}
.infoField-1{
    padding: 1rem;
}

.Tittle-Details{
    font-size: 10rem;
    padding-bottom: 1rem;
}
li{
    list-style: none;
}
.github .item-icon{
    font-size: 2.5rem;
    padding: 2rem 2rem 3rem 1rem;
}

@media only screen and (max-width: 1400px){
    .test{
        width: 100%;
        height: 70vh;
        padding: 1rem;
    }
.text1{
    font-size: 1.5rem;
}
    .Tittle-Details{
        font-size: 5.5rem;
    }

}